import { useForm as useFormReactHookForm } from 'react-hook-form';

function useForm({ defaultValues, mode = 'onBlur', resolver } = {}) {
	const methods = useFormReactHookForm({
		defaultValues,
		mode,
		resolver,
	});

	return methods;
}

export default useForm;
